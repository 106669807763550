import groq from 'groq'

import { blockFragment } from './blocks'
import { blogPostWithoutBodyFragment } from './blog'
import { complexPortableTextFragment } from './content'
import { imageFragment } from './image'
import { linkPageFragment } from './link'
import { muxVideoFragment, videoFragment } from './video'

// Blog author post grid Module
const blogAuthorPostGridModuleFragment = groq`
  options,
  $blogAuthorSlug != null => {
    "posts": *[
      _type == "blogPost" &&
      type != "template" &&
      locale == $locale &&
      author->slug.current == $blogAuthorSlug
    ]
      | order(_createdAt desc)
      | order(date desc)
    {
      ${blogPostWithoutBodyFragment}
    },
  },
`

// Blog category post grid module
const blogCategoryPostGridModuleFragment = groq`
  options,
  $blogCategorySlug != null => {
    "posts": *[
      _type == "blogPost" &&
      type != "template" &&
      locale == $locale &&
      categories[]->slug.current match $blogCategorySlug
    ]
      | order(_createdAt desc)
      | order(date desc)
    {
      ${blogPostWithoutBodyFragment}
    },
  },
`

// Blog post body module
const blogPostBodyModuleFragment = groq`
  content[] {
    ${complexPortableTextFragment}
  },
`

// Blog post grid module
const blogPostGridModuleFragment = groq`
  options,
  "posts": *[
    _type == "blogPost" &&
    type != "template" &&
    locale == $locale &&
    (!defined(^.author) || author->slug.current == ^.author->slug.current) &&
    (!defined(^.category) || categories[]->slug.current match ^.category->slug.current)
  ]
    | order(_createdAt desc)
    | order(date desc)
  {
    ${blogPostWithoutBodyFragment}
  },
`

// Blog post header module
const blogPostHeaderModuleFragment = groq`
  options,
  $blogPostSlug != null => {
    "post": *[
      _type == "blogPost" &&
      type != "template" &&
      locale == $locale &&
      slug.current == $blogPostSlug
    ]
      | order(_updatedAt desc)
    [0] {
      ${blogPostWithoutBodyFragment}
    },
  },
`

// Blog post read more module
const blogPostReadMoreModuleFragment = groq`
  $blogPostSlug != null => {
    "posts": *[
      _type == "blogPost" &&
      type != "template" &&
      locale == $locale &&
      slug.current != $blogPostSlug
    ] {
      "matchingCategoryCount": count((categories[]._ref)[
        @ in *[
          _type == "blogPost" &&
          type != "template" &&
          locale == $locale &&
          slug.current == $blogPostSlug
        ]
          | order(_updatedAt desc)
        [0].categories[]._ref
      ]),
      ${blogPostWithoutBodyFragment}
    }
      | order(_createdAt desc)
      | order(date desc)
      | order(matchingCategoryCount desc)
    [0...5],
  },
`

// Divider photo module
const dividerLogoModuleFragment = groq`
  logo,
`

// Divider photo module
const dividerPhotoModuleFragment = groq`
  photo {
    ${imageFragment}
  },
`

// Gallery module
const galleryModuleFragment = groq`
  title,
  items[] {
    _key,
    _type,
    title,
    _type == "photo" => {
      ${imageFragment}
    },
    _type == "video" => {
      ${videoFragment}
    },
  },
`

// Gift card form
const giftCardFormModuleFragment = groq`
  ...,
`

// Grid module
const gridModuleFragment = groq`
  ...,
  columns[] {
    ...,
    blocks[] {
      ${blockFragment}
    },
  },
`

// Hero module
const heroModuleFragment = groq`
  bgType,
  contentPosition,
  content[] {
    ${complexPortableTextFragment}
  },
  photos {
    mobilePhoto {
      ${imageFragment}
    },
    desktopPhoto {
      ${imageFragment}
    },
  },
  ${muxVideoFragment}
`

// Marquee module
const marqueeModuleFragment = groq`
  ...,
  content[] {
    ${complexPortableTextFragment}
  },
  items[] {
    ...,
    _type == "photo" => {
      ${imageFragment}
    },
  },
`

// Pricing plans module
const pricingPlansModuleFragment = groq`
  plans[] {
    ...,
    button {
      ...,
      page->{
        ${linkPageFragment}
      },
    },
  },
`

const baseModuleFragment = groq`
  _key,
  _type,
  _type == "blogAuthorPostGrid" => {
    ${blogAuthorPostGridModuleFragment}
  },
  _type == "blogCategoryPostGrid" => {
    ${blogCategoryPostGridModuleFragment}
  },
  _type == "blogPostBody" => {
    ${blogPostBodyModuleFragment}
  },
  _type == "blogPostGrid" => {
    ${blogPostGridModuleFragment}
  },
  _type == "blogPostHeader" => {
    ${blogPostHeaderModuleFragment}
  },
  _type == "blogPostReadMore" => {
    ${blogPostReadMoreModuleFragment}
  },
  _type == "dividerLogo" => {
    ${dividerLogoModuleFragment}
  },
  _type == "dividerPhoto" => {
    ${dividerPhotoModuleFragment}
  },
  _type == "gallery" => {
    ${galleryModuleFragment}
  },
  _type == "giftCardForm" => {
    ${giftCardFormModuleFragment}
  },
  _type == "grid" => {
    ${gridModuleFragment}
  },
  _type == "hero" => {
    ${heroModuleFragment}
  },
  _type == "marquee" => {
    ${marqueeModuleFragment}
  },
  _type == "pricingPlans" => {
    ${pricingPlansModuleFragment}
  },
  _type == "video" => {
    ${videoFragment}
  },
`

const predefinedPageModuleModuleFragment = groq`
  "pageModules": pageModule->pageModules[] {
    ${baseModuleFragment}
  },
`

export const moduleFragment = groq`
  ${baseModuleFragment}
  _type == 'predefinedPageModule' => {
    ${predefinedPageModuleModuleFragment}
  },
`
