import groq from 'groq'

import { blogPostWithoutBodyFragment } from './blog'
import {
  complexPortableTextFragment,
  simplePortableTextFragment,
} from './content'
import { imageFragment } from './image'
import { newsletterFragment } from './site'
import { videoFragment } from './video'

const freeformBlockFragment = groq`
  ...,
  content[] {
    ${complexPortableTextFragment}
  },
`

const htmlBlockFragment = groq`
  code,
`

const accordionsBlockFragment = groq`
  items[] {
    ...,
    content[] {
      ${simplePortableTextFragment}
    },
  },
`

const blogPostCardBlockFragment = groq`
  options,
  post->{
    ${blogPostWithoutBodyFragment}
  },
`

const contactFormBlockFragment = groq`
  ...,
  text[] {
    ${simplePortableTextFragment}
  },
  submitSuccessMessage[] {
    ${complexPortableTextFragment}
  },
  submitErrorMessage[] {
    ${complexPortableTextFragment}
  },
`

const contentCarouselBlockFragment = groq`
  items[] {
    _key,
    _type,
    _type == "freeform" => {
      ${freeformBlockFragment}
    },
    _type == "photo" => {
      ${imageFragment}
    },
    _type == "blogPost" => {
      "blogPost": *[
        _type == "blogPost" &&
        _id == ^._ref
      ][0] {
        ${blogPostWithoutBodyFragment}
      },
    },
    _type == "video" => {
      ${videoFragment}
    },
  },
`

const cookieDeclarationFragment = groq`
  active,
  "cookieBotId": *[
    _type == "generalSettings" &&
    locale == $locale
  ][0].cookieBotId,
`

const iframeBlockFragment = groq`
  ...,
`

const restaurantMenuBlockFragment = groq`
  heading,
  showCourses,
  "strings": *[
    _type == "strings" &&
    locale == $locale
  ][0] {
    servingSingular,
    servingPlural,
    perPerson,
    courseItemListLabel,
  },
  showCourses == true => {
    "courses": *[
      _type == "restaurantCourse"
    ] | order(orderRank) {
      ...,
      "items": items[][$locale],
    },
  },
  categories[]->{
    "name": name[$locale],
    "items": items[]->{
      _id,
      price,
      "title": title[$locale],
      "description": description[$locale],
    },
  },
`

const baseBlockFragment = groq`
  _key,
  _type,
  _type == "accordions" => {
    ${accordionsBlockFragment}
  },
  _type == "blogPostCard" => {
    ${blogPostCardBlockFragment}
  },
  _type == "contactForm" => {
    ${contactFormBlockFragment}
  },
  _type == "contentCarousel" => {
    ${contentCarouselBlockFragment}
  },
  _type == "cookieDeclaration" => {
    ${cookieDeclarationFragment}
  },
  _type == "freeform" => {
    ${freeformBlockFragment}
  },
  _type == 'html' => {
    ${htmlBlockFragment}
  },
  _type == "iframe" => {
    ${iframeBlockFragment}
  },
  _type == "newsletter" => {
    ${newsletterFragment}
  },
  _type == "restaurantMenu" => {
    ${restaurantMenuBlockFragment}
  },
  _type == "video" => {
    ${videoFragment}
  },
 `

const predefinedContentBlockFragment = groq`
  "contentBlocks": contentBlock->contentBlocks[] {
    ${baseBlockFragment}
  },
`

export const blockFragment = groq`
  ${baseBlockFragment}
  _type == 'predefinedContentBlock' => {
    ${predefinedContentBlockFragment}
  },
 `
